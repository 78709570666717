import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/authContext";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  doc,
  updateDoc,
  onSnapshot,
  addDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";

const Current = () => {
  const { currentUser } = useAuth();
  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState({ id: 0, name: "" });
  const [currentOrder, setCurrentOrder] = useState({ id: 0, name: "" });

  const [newDanceCode, setNewDanceCode] = useState("B");
  const [newStudentOrder, setNewStudentOrder] = useState(0);
  const [newStudentName, setNewStudentName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const db = getFirestore();
  const studentCollection = collection(db, "students");

  const danceCodeConstants = ["B", "C", "JAG", "JBG", "SAG", "SBG"];

  useEffect(() => {
    if (currentUser != null) {
      getStudents();
    }
    getCurrentStudent();
  }, []);

  const getStudents = () => {
    const q = query(studentCollection, orderBy("timeStamp", "asc"));
    getDocs(studentCollection, orderBy("timeStamp", "asc"))
      .then((snapshot) => {
        let stdList = [];

        snapshot.docs.forEach((doc) => {
          stdList.push({ ...doc.data() });
          console.log(doc.data().timeStamp.toDate());
        });

        stdList.sort(sortFunction);
        console.log(stdList);
        setStudentList(stdList);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  function sortFunction(a, b) {
    var dateA = new Date(a.timeStamp.toDate());
    var dateB = new Date(b.timeStamp.toDate());
    return dateA > dateB ? 1 : -1;
  }
  function getCurrentStudent() {
    const orderRef = doc(db, "order", "order1");
    onSnapshot(orderRef, (doc) => {
      setCurrentOrder(doc.data());
    });
  }

  const addNewStudent = async () => {
    await addDoc(collection(db, "students"), {
      danceCode: newDanceCode ? newDanceCode.toLocaleUpperCase() : "",
      order: newStudentOrder,
      name: newStudentName ? newStudentName.toLocaleUpperCase() : "",
      timeStamp: serverTimestamp(),
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
  };

  function updateCurrentStudent() {
    const orderRef = doc(db, "order", "order1");
    updateDoc(orderRef, {
      order: selectedStudent.order,
      name: selectedStudent.name,
      danceCode: selectedStudent.danceCode,
      timeStamp: selectedStudent.timeStamp,
    });
  }
  return (
    <>
      {/* non-admin user */}
      {currentUser === null && (
        <div className="w-full mx-4 h-screen flex self-center place-content-center place-items-center">
          <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
            <div className="text-center">
              <div className="mt-2">
                <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">
                  Masterpiece IBC
                </h3>
                <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl underline">
                  Current Dancer:
                </h3>

                <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">
                  {currentOrder.danceCode + currentOrder.order}
                </h3>
                <h2 className="text-gray-800 text-xl sm:text-2xl">
                  {currentOrder.name}
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* admin user */}
      {currentUser != null && (
        <div className="flex-col w-auto self-center place-content-center place-items-center">
          <div className="h-auto">
            <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
              <div className="text-center">
                <div className="mt-2">
                  {
                    <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl underline">
                      Current Dancer:
                    </h3>
                  }
                  <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">
                    {currentOrder.danceCode + currentOrder.order}
                  </h3>
                  <h2 className="text-gray-800 text-xl sm:text-2xl">
                    {currentOrder.name}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="h-auto">
            <div className="w-auto text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
              <div className="text-center">
                <div className="mt-2">
                  <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl underline">
                    Student List:
                  </h3>
                  <div className="flex my-3">
                    <p>Please select upcoming student below:</p>
                    <select
                      onChange={(e) => {
                        let studentObject = JSON.parse(e.target.value);
                        setSelectedStudent(studentObject);
                      }}
                      defaultValue="please select"
                    >
                      {studentList
                        ? studentList.map((option, index) => {
                            return (
                              <option
                                defaultValue="hello"
                                key={option.order}
                                value={JSON.stringify(option)}
                              >
                                {option.danceCode +
                                  option.order +
                                  " - " +
                                  option.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <button
                    className="flex-none w-auto mb-10 ml-10 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300"
                    onClick={() => getStudents()}
                  >
                    Refresh Student List
                  </button>
                  <button
                    className="flex-none w-auto ml-10 mr-10 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300"
                    onClick={() => updateCurrentStudent()}
                  >
                    Update Current Student
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="h-auto">
            <div className="w-auto text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
              <div className="text-center">
                <div className="mt-2">
                  <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl underline">
                    Input new student details here:
                  </h3>
                  <form onSubmit={onSubmit}>
                    <div>
                      <label className="text-sm text-gray-600 font-bold">
                        Dance Code (if ballet, input B):
                      </label>
                      <select
                        displayEmpty
                        required
                        onChange={(e) => {
                          setNewDanceCode(e.target.value);
                        }}
                      >
                        {danceCodeConstants.map((option, order) => {
                          return (
                            <option key={order} value={option}>
                              {option}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <label className="text-sm text-gray-600 font-bold">
                        Order Number
                      </label>
                      <input
                        type="number"
                        required
                        value={newStudentOrder}
                        onChange={(e) => {
                          setNewStudentOrder(e.target.value);
                        }}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                      />
                    </div>

                    <div>
                      <label className="text-sm text-gray-600 font-bold">
                        Student Name
                      </label>
                      <input
                        type="text"
                        required
                        value={newStudentName}
                        onChange={(e) => {
                          setNewStudentName(e.target.value);
                        }}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                      />
                    </div>
                    {errorMessage && (
                      <span className="text-red-600 font-bold">
                        {errorMessage}
                      </span>
                    )}
                    <button
                      className="flex-none w-auto ml-10 mr-10 px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300"
                      onClick={() => addNewStudent()}
                    >
                      Add New Student
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Current;
