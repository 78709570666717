import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Current from "./components/dance/current";
import Edit from "./components/dance/edit";
import Home from "./components/home ";

import Header from "./components/header";
import { AuthProvider } from "./contexts/authContext";
import { useRoutes } from "react-router-dom";

function App() {
  const routesArray = [
    {
      path: "*",
      element: <Current />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    // {
    //   path: "/register",
    //   element: <Register />,
    // },
    // {
    //   path: "/home",
    //   element: <Home />,
    // },
    // {
    //   path: "/edit",
    //   element: <Edit />,
    // },
    {
      path: "/dance",
      element: <Current />,
    },
  ];
  let routesElement = useRoutes(routesArray);
  return (
    <AuthProvider>
      <Header />
      <div className="w-full h-screen flex flex-col">{routesElement}</div>
    </AuthProvider>
  );
}

export default App;
