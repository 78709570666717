import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDpcVjaPbr1qUGwzUv6VWYluPbyJ8zGv0E",
  authDomain: "dance-app-ac720.firebaseapp.com",
  projectId: "dance-app-ac720",
  storageBucket: "dance-app-ac720.appspot.com",
  messagingSenderId: "483196565372",
  appId: "1:483196565372:web:a045f7eae48f189d27d6f9",
  measurementId: "G-Q64FKMX9RF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

// init db
const db = getFirestore();
const colRef = collection(db, "students");

// getDocs(colRef)
//   .then((snapshot) => {
//     let students = [];
//     snapshot.docs.forEach((doc) => {
//       students.push({ ...doc.data(), id: doc.id });
//     });
//     console.log(students);
//   })
//   .catch((err) => {
//     console.log(err.message);
//   });

export { app, auth };
